import { useMutation } from "@tanstack/react-query";
import { makeQueryRequest, HttpMethod, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const inviteMembers = async (data: {
  organisation_id: number;
  invitees: { email: string }[];
}): Promise<Response<null>> => {
  return await makeQueryRequest(`${HOST}/v1/organisations/${data.organisation_id}/invite`, {
    method: HttpMethod.POST,
    body: data,
  });
};

const members = {
  useInviteMembers: ({ onSuccess, onError }: { onSuccess: (data: Response<null>) => void; onError: () => void }) =>
    useMutation(inviteMembers, {
      onSuccess: onSuccess,
      onError: onError,
    }),
};

export default members;
