import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

const fetchAuthToken = async (organisationId: number): Promise<Response<{ token: string }>> => {
  return await makeQueryRequest(`${HOST}/v1/auth/token?organisation_id=${organisationId}`);
};

const queryKeys = {
  useAuthToken: (organisationId?: number) => ["token", organisationId],
};

const authentication = {
  queryKeys,
  useAuthToken: (organisationId?: number) =>
    useQuery({
      queryKey: queryKeys.useAuthToken(organisationId),
      queryFn: () => fetchAuthToken(organisationId!),
      select: (data) => data.data.token,
      enabled: Boolean(organisationId),
      // Cache the data for 45 minutes (in milliseconds)
      staleTime: 45 * 60 * 1000, // 45 minutes
      cacheTime: 45 * 60 * 1000, // 45 minutes
      refetchInterval: 45 * 60 * 1000, // Refetch after 45 minutes
    }),
};

export default authentication;
