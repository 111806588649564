export enum GAEventActionEnum {
  SIGN_IN = "sign_in",
  SIGN_OUT = "sign_out",
  PAGE_VIEW = "page_view",
  CLICK = "click",
  SIGN_UP = "sign_up",
  ORGANISATION_SETUP = "organisation_setup",
  CONNECTOR_CONFIGURED = "connector_configured",
  INVITE_MEMBERS = "invite_members",
  LOG_ROCKET_SESSION = "log_rocket_session",
}

export enum GAEventCategoryEnum {
  PAGE = "page",
  USER = "user",
  INTERNAL_LINK = "internal_link",
  DROPDOWN = "dropdown",
  BUTTON = "button",
  CAROUSEL_SCROLL = "carousel_scroll",
  CHANGE_TAB = "change_tab",
  DATE_FILTER = "date_filter",
  EXTERNAL_LINK = "external_link",
  FEEDBACK = "feedback",
}

export enum GAProductSectionEnum {
  CONNECTOR_CONFIGURED_ACTION = "submit config action success",
  INVITE_MEMBERS_ACTION = "invite members action",
  INVITE_MEMBERS_MODAL = "invite members modal",
  LOG_ROCKET_SESSION = "log rocket session",
  ORGANISATION_SETUP_ACTION = "organisation setup success",
  PLAN_EXPIRED_MODAL = "plan expired modal",
  SIDE_BAR = "side bar",
  SIGN_IN = "sign in",
  SIGN_OUT_ACTION = "sign out action",
  SIGN_UP_VERIFIED = "sign up email verification",
  TOP_BAR = "top bar",
  METRICS_CONFIGURATION_MODAL = "metrics configuration modal",
  CONFIGURE_FILTER_MODAL = "configure filter modal",
}

export type ProductSectionType = GAProductSectionEnum | string;

/**
 * * Optional parameters and there usage
 * @param pageTitle, PAGE_VIEW action
 * @param pageLocation, PAGE_VIEW action
 * @param sign_in_success, SIGN_IN action
 * @param log_rocket_url, LOG_ROCKET_SESSION action
 */
export type GAEventType = {
  action: GAEventActionEnum;
  category: GAEventCategoryEnum;
  label: string;
  payload: {
    public_org_id: string;
    product_section: ProductSectionType;
    pageTitle?: string;
    pageLocation?: string;
    sign_in_success?: boolean;
    log_rocket_url?: string;
  };
};
