import { useMemo } from "react";

import useSearchParams from "./useSearchParams";

const modifier = (val: any) => {
  return val;
};

const useUrlQueryState = <State, SavedState = State>(
  key: string,
  defaultState: State,
  fn: (val: SavedState, defaultState: State) => State = modifier
): [State, (value: State) => void] => {
  const [search, setSearch] = useSearchParams();

  let value = JSON.parse(JSON.stringify(defaultState));

  if (search[key] && typeof search[key] === "string") {
    value = JSON.parse(search[key] as string);
  }

  const setValue = (newValue: State) => {
    setSearch(key, JSON.stringify(newValue));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlValue = useMemo(() => fn(value, defaultState), [key, search]);
  return [urlValue, setValue];
};

export default useUrlQueryState;
