import { useQueries, useQuery } from "@tanstack/react-query";
import { FilterRequest, ServerFilterResponseType } from "app/screens/Dashboard/interfaces";
import { HttpMethod, makeQueryRequest } from "common/services/APIService";
import { REACT_APP_OCARINA_HOST } from "common/utils/constants";

const fetchFilters = async (filterRequest: FilterRequest, token: string): Promise<ServerFilterResponseType> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/filters/`,
    {
      method: HttpMethod.POST,
      body: filterRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const queryKeys = {
  useFilterRequest: (filterRequest: FilterRequest, token: string) => ["filter", JSON.stringify(filterRequest), token],
};

const metrics = {
  queryKeys,
  useFilterRequest: (filterRequest: FilterRequest | undefined, token: string | undefined) =>
    useQuery({
      queryKey: queryKeys.useFilterRequest(filterRequest!, token!),
      queryFn: () => fetchFilters(filterRequest!, token!),
      enabled: Boolean(filterRequest) && Boolean(token),
    }),
  useFilterRequests: (filterRequests: FilterRequest[], token: string | undefined) =>
    useQueries({
      queries: filterRequests.map((filterRequest) => ({
        queryKey: queryKeys.useFilterRequest(filterRequest, token!),
        queryFn: () => fetchFilters(filterRequest, token!),
        enabled: Boolean(token),
      })),
    }),
};

export default metrics;
