import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import authReducer from "./auth";
import connectorsReducer from "./connectors";
import i18nReducer from "./i18n";
import invitesReducer from "./invites";
import membersReducer from "./members";
import organisationsReducer from "./organisations";
import userPreferencesReducer from "./userPreferences";
import workflowReducer from "./workflow";

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    connectors: connectorsReducer,
    i18n: i18nReducer,
    auth: authReducer,
    workflow: workflowReducer,
    members: membersReducer,
    organisations: organisationsReducer,
    invites: invitesReducer,
    userPreferences: userPreferencesReducer,
  });

export default createRootReducer;
