import {
  DimType,
  DimFilter,
  ContentType,
  MetricCalculationType,
  MetricComparisonType,
} from "app/screens/Dashboard/interfaces";
import { DashboardComponentType } from "app/screens/Dashboard/interfaces/components";
import { DatePickerTypes } from "app/screens/Dashboard/interfaces/dateRangePicker";
import { ConnectorCategory } from "app/services/APIService/connectorsV2";

export interface DimensionInterface extends DimensionMetadataInterface {
  values: string[];
}

export interface DimensionMetadataInterface {
  id: string;
  key: string;
  header_dim_filter_value_key?: string;
  dim_filter_key?: string;
  dim_type: DimType;
  content_type: ContentType;
  label: string;
}

/**
 * ValueDirection is based on "ocarina".
 * * "positive": higher values are better.
 * * "negative": lower values are better.
 */
export type ValueDirection = "positive" | "negative";

type DashboardMode = "standard" | "benchmark" | "both";

export const DashboardComparisonsEnum = {
  ENABLED: "enabled",
  DISABLED: "disabled",
} as const;

export type DashboardComparisons = (typeof DashboardComparisonsEnum)[keyof typeof DashboardComparisonsEnum];

export interface MetricInterface {
  id: string;
  label: string;
  tooltip: string;
  format: string;
  tooltip_format: string;
  locale_overrides?: {
    currency: [string, string];
  };
  supported_dimensions: string[];
  table_dependencies: string[];
  value_direction: ValueDirection;
  comparison_type: MetricComparisonType;
  calculation: {
    metric_id: string;
    calculation_type: MetricCalculationType;
  };
  dashboard_mode: DashboardMode;
}

export type DashboardDimFilterInterface = {
  id: string;
  editable: boolean;
  lookup_type?: "exact" | "contains";
};

export type DateRangePickerInterface = {
  type: DatePickerTypes;
  display: boolean;
};

export interface DashboardInterface {
  id: string;
  version: string;
  label: string;
  description: string;
  category: string;
  comparisons: DashboardComparisons;
  current_availability: "available" | "unavailable";
  header_items: {
    dim_filters: DashboardDimFilterInterface[];
    date_range_picker: DateRangePickerInterface;
  };
  global_dim_filters: DimFilter[];
  global_value_filters: DimFilter[];
  dimensions: DimensionInterface[];
  metrics: MetricInterface[];
  components: DashboardComponentType[];
}

export interface DashboardMetadataInterface extends Omit<DashboardInterface, "dimensions"> {
  dimensions: Array<DimensionMetadataInterface>;
}

export type DashboardAvailability = {
  connected_sources: Record<ConnectorCategory, string[]>;
  data: Record<string, { count: number; sources: ConnectorCategory[] }>;
};
