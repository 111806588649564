import { Connector, ConnectorCategory } from "app/services/APIService/connectorsV2";

export interface CategoryGroup extends CategoryGroupBase {
  isRequired: boolean;
  connectors: Connector[];
}

interface CategoryGroupBase {
  id: string;
  label: string;
  description: string;
  connectorCategories: ConnectorCategory[];
}

export class ConnectorCategoryGroup implements CategoryGroupBase {
  static readonly ECOMMERCE_PLATFORM = new ConnectorCategoryGroup(
    "eCommercePlatform",
    "eCommerce",
    "Connect your eCommerce store provider",
    [ConnectorCategory.ECOMMERCE]
  );

  static readonly ANALYTICS_PLATFORM = new ConnectorCategoryGroup(
    "analyticsPlatform",
    "Analytics",
    "Connect all of your GA datasources",
    [ConnectorCategory.WEB_ANALYTICS]
  );

  static readonly MARKETING_PLATFORM = new ConnectorCategoryGroup(
    "marketingPlatform",
    "Marketing",
    "Connect all of your ad platforms",
    [ConnectorCategory.MARKETING]
  );

  static readonly ERP_AND_MARKETPLACE_PLATFORM = new ConnectorCategoryGroup(
    "erpAndMarketplacePlatform",
    "Marketplace and ERP",
    "Optionally connect marketplace channels or ERP systems",
    [ConnectorCategory.MARKETPLACE, ConnectorCategory.ERP]
  );

  private constructor(
    readonly id: string,
    readonly label: string,
    readonly description: string,
    readonly connectorCategories: ConnectorCategory[]
  ) {}

  static values() {
    return <Array<ConnectorCategoryGroup>>Object.values(this);
  }

  isValidCategory(connectorCategory: ConnectorCategory) {
    return this.connectorCategories.some((categoryGroup) => categoryGroup.includes(connectorCategory));
  }
}
