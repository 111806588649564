import { type DashboardComparisons } from "app/screens/Dashboard/interfaces";
import { DateRangeKeyType, DateRangeType } from "app/utils/dateutils";

export const DatePickerEnum = {
  DEFAULT: "default",
  COHORTS: "cohorts",
} as const;

export type DatePickerTypes = (typeof DatePickerEnum)[keyof typeof DatePickerEnum];

export interface DateRangePickerProps {
  dateRanges: { [key: string]: DateRangeKeyType };
  primaryDateRange: DateRangeKeyType;
  primaryDateRangeColor: string;
  label: string;
  handlePrimaryDateRangeChange: (range: DateRangeKeyType) => any;
  comparisonDateRanges: { [key: string]: DateRangeKeyType };
  comparisonDateRangeColors: string[];
  handleComparisonDateRangesChange: (ranges: { [key: string]: DateRangeKeyType }) => any;
  comparisons: DashboardComparisons;
}

export type RangePresetType<LabelType extends string> = {
  label: LabelType;
  isSelected: (range: DateRangeType) => boolean;
  range: () => DateRangeType;
  ldFlag?: string;
  isVisible?: boolean;
};

export enum PrimaryPresetRangeLabel {
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This Week (WTD)",
  LAST_WEEK = "Last Week",
  THIS_MONTH = "This Month (MTD)",
  LAST_MONTH = "Last Month",
  LAST_7_DAYS = "Last 7 Days",
  LAST_30_DAYS = "Last 30 Days",
  LAST_60_DAYS = "Last 60 Days",
  LAST_90_DAYS = "Last 90 Days",
  THIS_YEAR = "This Year (YTD)",
  THIS_BFCM = "This BFCM",
}

export enum ComparisonPresetRangeLabel {
  PREVIOUS_DAY = "Previous Day",
  PREVIOUS_WEEK = "Previous Week",
  PREVIOUS_MONTH = "Previous Month",
  PREVIOUS_YEAR = "Previous Year",
  PREVIOUS_30_DAYS = "Previous 30 Days",
  PREVIOUS_60_DAYS = "Previous 60 Days",
  PREVIOUS_90_DAYS = "Previous 90 Days",
  LAST_BFCM = "Last BFCM",
}

export enum CohortPrimaryPresetRangeLabel {
  THIS_MONTH = "This Month (MTD)",
  LAST_MONTH = "Last Month",
  THIS_YEAR = "This Year (YTD)",
  THIS_QUARTER = "This Quarter (QTD)",
  LAST_QUARTER = "Last Quarter",
  LAST_YEAR = "Last Year",
  LAST_6_MONTHS = "Last 6 Months",
  LAST_12_MONTHS = "Last 12 Months",
  LAST_24_MONTHS = "Last 24 Months",
}
