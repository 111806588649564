import {
  DimFilter,
  ValueFilter,
  DateRangePickerInterface,
  DashboardComponentType,
  AnalyticsComponentFilterValueMap,
} from "app/screens/Dashboard/interfaces";
import { DateRangeKeyType } from "app/utils/dateutils";

import useUrlQueryState from "./hooks/useUrlQueryState";

export type JsonDateRangeKeyType = {
  startDate: string;
  endDate: string;
  key: string;
  label: string;
};

export function getDateRangeFromJson(dateRange: JsonDateRangeKeyType): DateRangeKeyType {
  return {
    startDate: new Date(dateRange.startDate),
    endDate: new Date(dateRange.endDate),
    key: dateRange.key,
    label: dateRange.label || "", // Adding || "" in the end because the label was added later, and if there are older links without label - we don't want the url to break date range picker
  };
}

export function getDateRangesFromJson(dateRanges: Record<string, JsonDateRangeKeyType>) {
  const comparisonDateRanges: Record<string, DateRangeKeyType> = {};

  Object.keys(dateRanges).forEach((range: string) => {
    comparisonDateRanges[range] = getDateRangeFromJson(dateRanges[range]);
  });

  return comparisonDateRanges;
}

export function getComponentStateFromJson(
  value: AnalyticsComponentFilterValueMap,
  defaultValue: AnalyticsComponentFilterValueMap
) {
  // Fill missing keys in stored value with default values
  return { ...defaultValue, ...value };
}

export const createPrimaryDateRangeKey = (dateRangePickerType: DateRangePickerInterface["type"]) =>
  `${dateRangePickerType}-primary-date-range`;

export const createComparisonDateRangesKey = (dateRangePickerType: DateRangePickerInterface["type"]) =>
  `${dateRangePickerType}-comparison-date-ranges`;

export const createComponentKey = (
  dashboardId: string,
  componentType: DashboardComponentType["type"],
  componentOrderIndex: number | string
) => `${dashboardId}-${componentType}_${componentOrderIndex}`;

export const createDimFilterKey = (dashboardId: string) => `${dashboardId}-dim-filters`;

export const createValueFilterKey = (dashboardId: string) => `${dashboardId}-value-filters`;

export default {
  useDimFilterState: (dashboardId: string) => useUrlQueryState<DimFilter[]>(createDimFilterKey(dashboardId), []),
  useValueFilterState: (dashboardId: string) => useUrlQueryState<ValueFilter[]>(createValueFilterKey(dashboardId), []),
  usePrimaryDateRangeState: (
    dateRangePickerType: DateRangePickerInterface["type"],
    defaultPrimaryDateRange: DateRangeKeyType
  ) =>
    useUrlQueryState<DateRangeKeyType, JsonDateRangeKeyType>(
      createPrimaryDateRangeKey(dateRangePickerType),
      defaultPrimaryDateRange,
      getDateRangeFromJson
    ),
  useComparisonDateRangesState: (
    dateRangePickerType: DateRangePickerInterface["type"],
    defaultComparisonDateRanges: Record<string, DateRangeKeyType>
  ) =>
    useUrlQueryState<Record<string, DateRangeKeyType>, Record<string, JsonDateRangeKeyType>>(
      createComparisonDateRangesKey(dateRangePickerType),
      defaultComparisonDateRanges,
      getDateRangesFromJson
    ),
  useComponentState: (
    dashboardId: string,
    componentType: DashboardComponentType["type"],
    componentOrderIndex: number | string,
    defaultValue: AnalyticsComponentFilterValueMap
  ) =>
    useUrlQueryState<AnalyticsComponentFilterValueMap>(
      createComponentKey(dashboardId, componentType, componentOrderIndex),
      defaultValue,
      getComponentStateFromJson
    ),
};
