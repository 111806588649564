import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getInvite = createAsyncThunk<AsyncThunkReturnType, APIServiceFunctionArg<typeof APIService.invites.get>>(
  "invites/getInvite",
  async (data) => await APIService.invites.get(data).then((res) => res.json())
);

export const acceptInvite = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.invites.accept>
>(
  "invites/acceptInvite",
  async (data: { invite_id_hash: string }) => await APIService.invites.accept(data).then((res) => res.json())
);

export const getInvites = createAsyncThunk<AsyncThunkReturnType, APIServiceFunctionArg<typeof APIService.invites.list>>(
  "invites/getInvites",
  async (data) => await APIService.invites.list(data).then((res) => res.json())
);

export const invalidateInvite = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.invites.invalidate>
>("invites/invalidateInvite", (data: { invite_id_hash: string }) => {
  return APIService.invites.invalidate(data).then((res) => res.json());
});

export interface Invite {
  id: number;
  invite_id_hash: string;
  organisation_name: string;
  user_email: string;
  role: { name: string };
  expires: string;
  created_at: string;
  inviter_email: string;
}

// Define a type for the slice state
export interface InvitesState {
  invite: Invite | undefined;
  invites: Invite[];
  status: string | undefined;
  acceptStatus: string | undefined;
  listStatus: string | undefined;
}

// Define the initial state using that type
export const initialState: InvitesState = {
  invite: undefined,
  invites: [],
  status: undefined,
  acceptStatus: undefined,
  listStatus: undefined,
};

export const invitesSlice = createSlice({
  name: "invites",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvite.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getInvite.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.status = APICallState.SUCCESS;
        state.invite = action.payload.data.invite;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getInvite.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(getInvites.pending, (state) => {
      state.listStatus = APICallState.LOADING;
    });

    builder.addCase(getInvites.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.listStatus = APICallState.SUCCESS;
        state.invites = action.payload.data.invites;
      } else {
        state.listStatus = APICallState.FAILED;
      }
    });

    builder.addCase(getInvites.rejected, (state) => {
      state.listStatus = APICallState.FAILED;
    });

    builder.addCase(acceptInvite.pending, (state) => {
      state.acceptStatus = APICallState.LOADING;
    });

    builder.addCase(acceptInvite.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.acceptStatus = APICallState.SUCCESS;
      } else {
        state.acceptStatus = APICallState.FAILED;
      }
    });

    builder.addCase(acceptInvite.rejected, (state) => {
      state.acceptStatus = APICallState.FAILED;
    });

    builder.addCase(invalidateInvite.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        const invalidatedId = action.meta.arg.invite_id_hash;
        state.invites = state.invites.filter((invite: Invite) => invite.invite_id_hash !== invalidatedId);
      }
    });
  },
});

export default invitesSlice.reducer;
