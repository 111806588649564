import { useEffect } from "react";

import CookieService, { CookieNames } from "common/services/CookieService";
import { getAndSetCurrentOrganisation } from "common/slices/organisations";
import { RootState } from "common/utils/store";
import { useDispatch, useSelector } from "react-redux";

export const useCurrentOrganisation = () => {
  const dispatch = useDispatch();
  const currentOrganisation = useSelector((state: RootState) => state.organisations.currentOrganisation);

  useEffect(() => {
    if (!currentOrganisation) {
      const organisationId: string | undefined = CookieService.get(CookieNames.ORGANISATION_ID);
      if (organisationId) {
        dispatch(getAndSetCurrentOrganisation({ id: Number(organisationId) }));
      }
    }
  }, [dispatch, currentOrganisation]);

  return currentOrganisation;
};
