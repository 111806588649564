import {
  DashboardInterface,
  DashboardMetadataInterface,
  DimensionInterface,
  MetricInterface,
  DashboardDimFilterInterface,
} from "app/screens/Dashboard/interfaces";
import {
  DimSplit,
  DimFilter,
  FilterRequest,
  Granularity,
  MetricRequest,
  ValueFilter,
} from "app/screens/Dashboard/interfaces/requests";
import { DateRangeKeyType } from "app/utils/dateutils";

import { getServerDateRangeFromDateRange } from "./utils";

type MetricRequestArgs = {
  primaryDateRange: DateRangeKeyType;
  comparisonDateRanges: DateRangeKeyType[];
  organisationId: string;
  granularity: Granularity;
  metric: string;
  dimensions?: DimSplit[];
  dimFilters?: DimFilter[];
  valueFilters?: ValueFilter[];
};

export const getMetricRequest = (
  {
    primaryDateRange,
    comparisonDateRanges,
    organisationId,
    metric,
    granularity,
    dimensions = [],
    dimFilters = [],
    valueFilters = [],
  }: MetricRequestArgs,
  supportedDimensions?: string[]
): MetricRequest => {
  return {
    metrics: [metric],
    dimensions,
    primary_date_range: getServerDateRangeFromDateRange(primaryDateRange),
    comparison_date_ranges: comparisonDateRanges.map((comparisonDateRange) =>
      getServerDateRangeFromDateRange(comparisonDateRange)
    ),
    granularity,
    organisation_id: organisationId,
    dim_filters: supportedDimensions
      ? dimFilters
          .filter((dimFilter) => {
            // `dimFilters` override here removes the unsupported dim filters inside the metric request.
            return supportedDimensions.includes(dimFilter.key) || dimFilter?.dim_filter_key;
          })
          .map((dimFilter) => ({
            ...dimFilter,
            key: dimFilter?.dim_filter_key ?? dimFilter.key,
          }))
      : [],
    // We want to only pass value filters in metric requests if the valueFilter metric matches the metric in the request and if the value filter has a value.
    value_filters: valueFilters.filter((valueFilter) => valueFilter.metric === metric && valueFilter.values.length > 0),
  };
};

export type MetricRequestsArgs = Omit<MetricRequestArgs, "metric"> & {
  metrics: string[];
};

export const getMetricRequests = (
  { metrics, ...data }: MetricRequestsArgs,
  metricsMetadata?: Record<MetricInterface["id"], MetricInterface>
): MetricRequest[] => {
  return metrics.map((metric) => {
    if (metricsMetadata) {
      const { [metric]: { supported_dimensions: supportedDimensions = [] } = {} } = metricsMetadata;

      return getMetricRequest(
        {
          ...data,
          metric: metric,
        },
        supportedDimensions
      );
    } else {
      return getMetricRequest({ ...data, metric: metric });
    }
  });
};

type FilterRequestArgs = {
  organisationId: string;
  dashboardDimFilters: DashboardDimFilterInterface[];
  dimFilters?: DimFilter[];
  dimensionsMetadataMap: Record<DimensionInterface["id"], Omit<DimensionInterface, "values">>;
  primaryDateRange: DateRangeKeyType;
  comparisonDateRanges: DateRangeKeyType[];
};

const getFilterRequest = ({
  organisationId,
  dashboardDimFilters = [],
  dimFilters = [],
  dimensionsMetadataMap,
  primaryDateRange,
  comparisonDateRanges,
}: FilterRequestArgs): FilterRequest => {
  return {
    organisation_id: organisationId,
    dimensions: dashboardDimFilters.map((dimension) => ({
      dim_type: dimensionsMetadataMap[dimension.id].dim_type,
      key: dimensionsMetadataMap[dimension.id]?.header_dim_filter_value_key ?? dimensionsMetadataMap[dimension.id].key,
    })),
    dim_filters: dimFilters,
    primary_date_range: getServerDateRangeFromDateRange(primaryDateRange),
    comparison_date_ranges: comparisonDateRanges.map((comparisonDateRange) =>
      getServerDateRangeFromDateRange(comparisonDateRange)
    ),
  };
};

export const getFilterRequests = ({
  organisationId,
  dashboardDimFilters = [],
  dimFilters = [],
  dimensionsMetadataMap,
  primaryDateRange,
  comparisonDateRanges,
}: FilterRequestArgs): FilterRequest[] => {
  return dashboardDimFilters.map((dimension) =>
    getFilterRequest({
      organisationId,
      dashboardDimFilters: [{ id: dimension.id, editable: dimension.editable }],
      dimFilters,
      dimensionsMetadataMap,
      primaryDateRange,
      comparisonDateRanges,
    })
  );
};

export const getDashboardWithFilterValues = ({
  dashboard,
  filtersResponse,
}: {
  dashboard: DashboardMetadataInterface | undefined;
  filtersResponse: Record<string, string[]>;
}): DashboardInterface | undefined => {
  if (!dashboard || Object.keys(dashboard).length === 0) return;
  return {
    ...dashboard,
    dimensions: dashboard.dimensions.map((dimension) => ({
      ...dimension,
      values: filtersResponse[dimension?.header_dim_filter_value_key ?? dimension.key] || [],
    })),
  };
};

export const getExportRequest = (
  {
    primaryDateRange,
    comparisonDateRanges,
    organisationId,
    metrics,
    granularity,
    dimensions = [],
    dimFilters = [],
    valueFilters = [],
  }: MetricRequestsArgs,
  { metricsMetadata }: { metricsMetadata: Record<MetricInterface["id"], MetricInterface> }
): MetricRequest => {
  const supportedDimensions = [
    ...new Set(
      metrics
        .map((metric) => {
          const {
            [metric]: { supported_dimensions: metricSupportedDimensions = [] },
          } = metricsMetadata;

          return metricSupportedDimensions;
        })
        .flat()
    ),
  ];

  return {
    metrics: metrics,
    dimensions,
    primary_date_range: getServerDateRangeFromDateRange(primaryDateRange),
    comparison_date_ranges: comparisonDateRanges.map((comparisonDateRange) =>
      getServerDateRangeFromDateRange(comparisonDateRange)
    ),
    granularity,
    organisation_id: organisationId,
    dim_filters: dimFilters
      .filter((dimFilter) => {
        // `dimFilters` override here removes the unsupported dim filters inside the metric request.
        return supportedDimensions.includes(dimFilter.key);
      })
      .map((dimFilter) => ({
        ...dimFilter,
        key: dimFilter?.dim_filter_key ?? dimFilter.key,
      })),
    value_filters: valueFilters,
  };
};
