import { useQuery } from "@tanstack/react-query";
import { ConnectorType } from "common/interfaces/connectors/connectorType";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export type ConnectorAuthStatus =
  | "authenticated"
  | "syncing"
  | "complete"
  | "failed"
  | "health_check_successful"
  | "health_check_failed"
  | "authentication_required"
  | "historic_complete";

export interface ConnectorAuthorisation {
  backfill_finished: boolean;
  connector_category: string;
  connector_type: string;
  connector_type_name: string;
  created_at: string;
  created_by: string;
  destinations_synced: string[];
  id: number;
  organisation_id: number;
  remote_identifier?: string;
  status: ConnectorAuthStatus;
  updated_at: string | undefined;
}

export type ConnectorAuthorisationResponse = {
  connector_authorisations: ConnectorAuthorisation[];
};

const fetchConnectorsAuths = async (
  organisationId: number | undefined
): Promise<Response<ConnectorAuthorisationResponse>> => {
  return await makeQueryRequest(`${HOST}/v1/connector_authorisations?organisation_id=${organisationId}`);
};

const selectConnectorAuths: (response: {
  data: { connector_authorisations: ConnectorAuthorisation[] };
}) => ConnectorAuthorisation[] = (response) => response.data?.connector_authorisations;

const connectorAuthorisationsV2 = {
  useConnectorAuths: (organisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectorAuthorisations", organisationId],
      queryFn: () => fetchConnectorsAuths(organisationId),
      select: selectConnectorAuths,
      enabled: !!organisationId && Number.isInteger(organisationId),
    }),
};

export default connectorAuthorisationsV2;

export function parseRemoteIdentifier(connectorAuth: ConnectorAuthorisation) {
  const identifier = connectorAuth.remote_identifier;
  if (!identifier) {
    return { name: "-", id: undefined };
  }

  // support for the following formats:
  // <name> (<optionalPart>) (<id>)
  // <name> (<id>)
  // <name>
  const match = /^\s*([^(]+?)\s*(?:\(([^)]*)\))?\s*\(([^)]*)\)\s*$/.exec(identifier.toString());

  if (!match) {
    return { name: identifier, id: undefined };
  }

  const [, baseName, optionalPart, id] = match;
  const name = optionalPart ? `${baseName} (${optionalPart})` : baseName;

  // Google Ads connectors have inverted names and ids
  if (connectorAuth.connector_type === ConnectorType.GOOGLE_ADS) {
    return { id: name, name: id };
  }
  return { name, id };
}
