import { RangePresetType, PrimaryPresetRangeLabel, ComparisonPresetRangeLabel } from "app/screens/Dashboard/interfaces";
import { createStaticRanges } from "app/screens/Dashboard/utils";
import { dateRangePresets, areRangeEqual, DateRangeType, DateRangeKeyType } from "app/utils/dateutils";
import {
  differenceInDays,
  endOfDay,
  subDays,
  isSameDay,
  isMonday,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";

export const primaryAndComparisonPresetRangeDefaultsMap: Record<PrimaryPresetRangeLabel, ComparisonPresetRangeLabel> = {
  [PrimaryPresetRangeLabel.YESTERDAY]: ComparisonPresetRangeLabel.PREVIOUS_DAY,
  [PrimaryPresetRangeLabel.THIS_WEEK]: ComparisonPresetRangeLabel.PREVIOUS_WEEK,
  [PrimaryPresetRangeLabel.LAST_WEEK]: ComparisonPresetRangeLabel.PREVIOUS_WEEK,
  [PrimaryPresetRangeLabel.THIS_MONTH]: ComparisonPresetRangeLabel.PREVIOUS_MONTH,
  [PrimaryPresetRangeLabel.LAST_MONTH]: ComparisonPresetRangeLabel.PREVIOUS_MONTH,
  [PrimaryPresetRangeLabel.THIS_YEAR]: ComparisonPresetRangeLabel.PREVIOUS_YEAR,
  [PrimaryPresetRangeLabel.LAST_7_DAYS]: ComparisonPresetRangeLabel.PREVIOUS_WEEK,
  [PrimaryPresetRangeLabel.LAST_30_DAYS]: ComparisonPresetRangeLabel.PREVIOUS_30_DAYS,
  [PrimaryPresetRangeLabel.LAST_60_DAYS]: ComparisonPresetRangeLabel.PREVIOUS_60_DAYS,
  [PrimaryPresetRangeLabel.LAST_90_DAYS]: ComparisonPresetRangeLabel.PREVIOUS_90_DAYS,
  [PrimaryPresetRangeLabel.THIS_BFCM]: ComparisonPresetRangeLabel.LAST_BFCM,
};

export const primaryPresetRanges = () => {
  return createStaticRanges([
    {
      label: PrimaryPresetRangeLabel.THIS_BFCM,
      range: () => dateRangePresets.thisBlackFriday(),
      ldFlag: "dqiDashboardBlackFriday",
    },
    {
      label: PrimaryPresetRangeLabel.YESTERDAY,
      range: () => dateRangePresets.lastDay(),
    },
    {
      label: PrimaryPresetRangeLabel.THIS_WEEK,
      range: () => dateRangePresets.weekToDate(),
      isVisible: !isMonday(new Date()),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_WEEK,
      range: () => dateRangePresets.lastWeek(),
    },
    {
      label: PrimaryPresetRangeLabel.THIS_MONTH,
      range: () => dateRangePresets.monthToDate(),
      isVisible: !isSameDay(new Date(), startOfMonth(new Date())),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_MONTH,
      range: () => dateRangePresets.lastMonth(),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_7_DAYS,
      range: () => dateRangePresets.last7Days(),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_30_DAYS,
      range: () => dateRangePresets.last30Days(),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_60_DAYS,
      range: () => dateRangePresets.last60Days(),
    },
    {
      label: PrimaryPresetRangeLabel.LAST_90_DAYS,
      range: () => dateRangePresets.last90Days(),
    },
    {
      label: PrimaryPresetRangeLabel.THIS_YEAR,
      range: () => dateRangePresets.yearToDate(),
      isVisible: !isSameDay(new Date(), startOfYear(new Date())),
    },
  ]);
};

export const getComparisonPresetRanges = (primaryDateRange: DateRangeType) => {
  const isLastDay = areRangeEqual(primaryDateRange, dateRangePresets.lastDay());
  const isWeekToDate = areRangeEqual(primaryDateRange, dateRangePresets.weekToDate());
  const isLastWeek = areRangeEqual(primaryDateRange, dateRangePresets.lastWeek());
  const shouldBeDayAligned = isLastDay || isWeekToDate || isLastWeek;
  const ranges = [
    {
      label: ComparisonPresetRangeLabel.LAST_BFCM,
      range: () => dateRangePresets.lastBlackFriday(),
      ldFlag: "dqiDashboardBlackFriday",
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_DAY,
      range: () => dateRangePresets.previousDay(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_WEEK,
      range: () => dateRangePresets.previousWeek(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_MONTH,
      range: () =>
        shouldBeDayAligned
          ? dateRangePresets.dayAlignedPreviousMonth(primaryDateRange)
          : dateRangePresets.previousMonth(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_YEAR,
      range: () =>
        shouldBeDayAligned
          ? dateRangePresets.dayAlignedPreviousYear(primaryDateRange)
          : dateRangePresets.previousYear(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_30_DAYS,
      range: () => dateRangePresets.previous30Days(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_60_DAYS,
      range: () => dateRangePresets.previous60Days(primaryDateRange),
    },
    {
      label: ComparisonPresetRangeLabel.PREVIOUS_90_DAYS,
      range: () => dateRangePresets.previous90Days(primaryDateRange),
    },
  ];
  return createStaticRanges<ComparisonPresetRangeLabel>(ranges);
};

export const getComparisonPresetRangesMap = (primaryDateRange: DateRangeType) => {
  const presetRangesMap: Record<string, RangePresetType<ComparisonPresetRangeLabel>> = {};
  getComparisonPresetRanges(primaryDateRange).forEach((presetRange) => {
    presetRangesMap[presetRange.label] = presetRange;
  });
  return presetRangesMap as Record<ComparisonPresetRangeLabel, RangePresetType<ComparisonPresetRangeLabel>>;
};

export const getComparisonPresetRangeLabel = (range: DateRangeType, primaryDateRange: DateRangeType): string => {
  const staticRanges = [...getComparisonPresetRanges(primaryDateRange)].filter((staticRange) => {
    return staticRange.isSelected(range);
  });
  if (staticRanges.length && staticRanges[0].label) {
    return staticRanges[0].label;
  }
  return "";
};

export const getDefaultComparisonRange = <State extends DateRangeType>(primaryDateRange: State) => {
  const diffDays = differenceInDays(primaryDateRange.endDate, primaryDateRange.startDate);
  const comparisonPresetsMap = getComparisonPresetRangesMap(primaryDateRange);
  let comparisonDateRange1: DateRangeKeyType;
  const comparisonPresetLabel = primaryDateRange.label
    ? primaryAndComparisonPresetRangeDefaultsMap[primaryDateRange.label as PrimaryPresetRangeLabel]
    : undefined;
  if (comparisonPresetLabel) {
    comparisonDateRange1 = {
      key: "comparisonDateRange1",
      label: comparisonPresetLabel,
      ...comparisonPresetsMap[comparisonPresetLabel].range(),
    };
  } else if (diffDays < 365 && diffDays > 31) {
    comparisonDateRange1 = {
      key: "comparisonDateRange1",
      label: ComparisonPresetRangeLabel.PREVIOUS_YEAR,
      ...comparisonPresetsMap[ComparisonPresetRangeLabel.PREVIOUS_YEAR].range(),
    };
  } else if (diffDays <= 31 && diffDays > 7) {
    comparisonDateRange1 = {
      key: "comparisonDateRange1",
      label: ComparisonPresetRangeLabel.PREVIOUS_MONTH,
      ...comparisonPresetsMap[ComparisonPresetRangeLabel.PREVIOUS_MONTH].range(),
    };
  } else if (diffDays <= 7 && diffDays > 1) {
    comparisonDateRange1 = {
      key: "comparisonDateRange1",
      label: ComparisonPresetRangeLabel.PREVIOUS_WEEK,
      ...comparisonPresetsMap[ComparisonPresetRangeLabel.PREVIOUS_WEEK].range(),
    };
  } else if (diffDays === 1) {
    comparisonDateRange1 = {
      key: "comparisonDateRange1",
      label: ComparisonPresetRangeLabel.PREVIOUS_DAY,
      ...comparisonPresetsMap[ComparisonPresetRangeLabel.PREVIOUS_DAY].range(),
    };
  } else {
    const endDate = endOfDay(subDays(primaryDateRange.startDate, 1));
    const startDate = startOfDay(subDays(endDate, diffDays));
    comparisonDateRange1 = { key: "comparisonDateRange1", label: "", endDate, startDate };
  }
  return {
    primaryDateRange,
    comparisonDateRange1,
  };
};
