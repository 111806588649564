import { history } from "common/utils/store";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const updateUrlParams = (key: string, value: string) => {
  const search = queryString.parse(history.location.search);
  search[key] = value;
  history.replace({ search: queryString.stringify(search) });
};

const useSearchParams = (): [queryString.ParsedQuery<string>, (key: string, value: string) => void] => {
  const location = useLocation();
  const search = queryString.parse(location.search);

  const setSearch = (key: string, value: string) => {
    updateUrlParams(key, value);
  };

  return [search, setSearch];
};

export default useSearchParams;
