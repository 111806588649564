export default (state: any) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    auth: { webSocketToken, ...auth },
  } = state;
  return {
    ...state,
    auth,
  };
};
