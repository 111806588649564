import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response, ErrorResponse } from "common/services/APIService";
import { HOST } from "common/utils/constants";

export enum Products {
  ACTIONS = "actions",
  ANALYTICS = "analytics",
  BENCHMARKS = "benchmarks",
}

export enum ProductAccess {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

type ProductPermissionsResponse = Promise<Response<Record<Products, ProductAccess>>>;

const fetchProductPermissions = async (organisation_id: number | undefined): ProductPermissionsResponse =>
  await makeQueryRequest(`${HOST}/v1/product_permissions/?organisation_id=${organisation_id}`);

const productPermissions = {
  useProductPermissions: (organisation_id: number | undefined) =>
    useQuery({
      queryKey: ["ProductPermissions", organisation_id],
      queryFn: () => fetchProductPermissions(organisation_id),
      onError: (err: ErrorResponse) => err,
      enabled: !!organisation_id,
    }),
};

export default productPermissions;
