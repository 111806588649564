import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const members = {
  list: (data: { organisation_id: number }) => makeRequest(`${HOST}/v1/organisations/${data.organisation_id}/members`),
  invalidate: (data: { organisation_id: number; id: number; onFinish: CallableFunction }) =>
    makeRequest(`${HOST}/v1/organisations/${data.organisation_id}/remove_user`, {
      method: "PUT",
      body: { id: data.id },
    }),
};

export default members;
