import {
  DashboardInterface,
  CohortPrimaryPresetRangeLabel,
  RangePresetType,
  ComparisonPresetRangeLabel,
  PrimaryPresetRangeLabel,
} from "app/screens/Dashboard/interfaces";
import { DateRangePickerInterface } from "app/screens/Dashboard/interfaces/dashboard";
import { DateRangeKeyType, dateRangePresets, areRangeEqual, DateRangeType } from "app/utils/dateutils";

export const getDefaultDateRangeState = (
  data: DateRangePickerInterface,
  dashboardComparisons: DashboardInterface["comparisons"],
  dashboardId: string
) => {
  let primaryDateRange: DateRangeKeyType;
  let comparisonDateRanges: Record<string, DateRangeKeyType>;

  if (dashboardId === "purchase-patterns") {
    primaryDateRange = {
      ...dateRangePresets.lastMonth(),
      key: "primaryDateRange",
      label: PrimaryPresetRangeLabel.LAST_MONTH,
    };
    comparisonDateRanges = {};
  } else if (dashboardId === "black-friday") {
    primaryDateRange = {
      ...dateRangePresets.thisBlackFriday(),
      key: "primaryDateRange",
      label: PrimaryPresetRangeLabel.THIS_BFCM,
    };
    if (dashboardComparisons === "enabled") {
      comparisonDateRanges = {
        comparisonDateRange1: {
          ...dateRangePresets.lastBlackFriday(),
          key: "comparisonDateRange1",
          label: ComparisonPresetRangeLabel.LAST_BFCM,
        },
      };
    } else {
      comparisonDateRanges = {};
    }
  } else if (data.type === "cohorts") {
    primaryDateRange = {
      ...dateRangePresets.last12Months(),
      key: "primaryDateRange",
      label: CohortPrimaryPresetRangeLabel.LAST_12_MONTHS,
    };
    comparisonDateRanges = {};
  } else {
    primaryDateRange = {
      ...dateRangePresets.last30Days(),
      key: "primaryDateRange",
      label: PrimaryPresetRangeLabel.LAST_30_DAYS,
    };
    if (dashboardComparisons === "enabled") {
      comparisonDateRanges = {
        comparisonDateRange1: {
          ...dateRangePresets.previous30Days(primaryDateRange),
          key: "comparisonDateRange1",
          label: ComparisonPresetRangeLabel.PREVIOUS_30_DAYS,
        },
      };
    } else {
      comparisonDateRanges = {};
    }
  }
  return {
    defaultPrimaryDateRange: primaryDateRange,
    defaultComparisonDateRanges: comparisonDateRanges,
  };
};

export function createStaticRanges<LabelType extends string>(
  ranges: Omit<RangePresetType<LabelType>, "isSelected">[]
): RangePresetType<LabelType>[] {
  return ranges
    .filter((range) => range.isVisible === undefined || range.isVisible)
    .map((range: Omit<RangePresetType<LabelType>, "isSelected">) => {
      const newRange: RangePresetType<LabelType> = {
        isSelected(range): boolean {
          const definedLabel = this.label;
          if (definedLabel === range.label) return true;
          const definedRange = this.range();
          return areRangeEqual(definedRange, range);
        },
        ...range,
      };
      return newRange;
    });
}

export const getPrimaryPresetRangeLabel = (
  primaryPresetRanges: RangePresetType<string>[],
  range: DateRangeType
): string => {
  const staticRanges = [...primaryPresetRanges].filter((staticRange) => {
    return staticRange.isSelected(range);
  });
  if (staticRanges.length && staticRanges[0].label) {
    return staticRanges[0].label;
  }
  return "";
};
