import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { ConnectorAuthorisation } from "app/services/APIService/connectorAuthorisationsV2";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import { AsyncThunkReturnType } from "common/slices/types";

export const getConnectorAuth = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.get>
>(
  "connectors/getConnectorAuth",
  async (data) => await APIService.connector_authorisations.get(data).then((res) => res.json())
);

export const deleteConnectorAuth: any = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.deleteAuth>
>(
  "connectors/deleteConnectorAuth",
  async (data) => await APIService.connector_authorisations.deleteAuth(data).then((res) => res.json())
);

export const getConnectorAuths: any = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.connector_authorisations.list>
>(
  "connectors/getConnectorAuths",
  async (organisation_id) => await APIService.connector_authorisations.list(organisation_id).then((res) => res.json())
);

// Define a type for the slice state

export interface ConnectorsState {
  connector_authorisations: ConnectorAuthorisation[];
  connector_authorisation: ConnectorAuthorisation | undefined;
  getAuthStatus: string | undefined;
  listAuthStatus: string | undefined;
  deleteAuthStatus: string | undefined;
}

// Define the initial state using that type
export const initialState: ConnectorsState = {
  connector_authorisations: [],
  connector_authorisation: undefined,
  getAuthStatus: undefined,
  listAuthStatus: undefined,
  deleteAuthStatus: undefined,
};

export const connectorsSlice = createSlice({
  name: "connectors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectorAuth.pending, (state) => {
      state.getAuthStatus = APICallState.LOADING;
    });

    builder.addCase(getConnectorAuth.fulfilled, (state, action) => {
      state.getAuthStatus = APICallState.SUCCESS;
      state.connector_authorisation = action.payload?.data?.connector_authorisation || undefined;
    });

    builder.addCase(getConnectorAuth.rejected, (state) => {
      state.getAuthStatus = APICallState.FAILED;
    });

    builder.addCase(deleteConnectorAuth.pending, (state) => {
      state.deleteAuthStatus = APICallState.LOADING;
    });

    builder.addCase(deleteConnectorAuth.fulfilled, (state, action) => {
      if (action.payload.status_code === 204) {
        state.deleteAuthStatus = APICallState.SUCCESS;
        state.connector_authorisations = state.connector_authorisations.filter(
          (connector_authorisation: any) => connector_authorisation.id !== action.meta.arg.connector_authorisation_id
        );
      } else {
        state.deleteAuthStatus = APICallState.FAILED;
      }
    });

    builder.addCase(deleteConnectorAuth.rejected, (state) => {
      state.deleteAuthStatus = APICallState.FAILED;
    });

    builder.addCase(getConnectorAuths.pending, (state) => {
      state.listAuthStatus = APICallState.LOADING;
    });

    builder.addCase(getConnectorAuths.fulfilled, (state, action) => {
      state.listAuthStatus = APICallState.SUCCESS;
      state.connector_authorisations = action.payload?.data?.connector_authorisations || [];
    });

    builder.addCase(getConnectorAuths.rejected, (state) => {
      state.listAuthStatus = APICallState.FAILED;
    });
  },
});

export default connectorsSlice.reducer;
