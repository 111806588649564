import { ConnectorCategory } from "app/services/APIService/connectorsV2";
import { ConnectorType } from "common/interfaces/connectors/connectorType";

export const HISTORICAL_CONNECTOR_TYPES = [ConnectorType.GOOGLE_ANALYTICS];

export const connectorCategoryLabels: Record<ConnectorCategory, string> = {
  [ConnectorCategory.ECOMMERCE]: "eCommerce",
  [ConnectorCategory.WEB_ANALYTICS]: "Analytics",
  [ConnectorCategory.MARKETING]: "Marketing",
  [ConnectorCategory.ERP]: "ERP",
  [ConnectorCategory.EMAIL]: "Email",
  [ConnectorCategory.ECOMMERCE_SUBSCRIPTION]: "eCommerce Subscription",
  [ConnectorCategory.MARKETPLACE]: "Marketplace",
};
