import { DimFilter, ValueFilter } from "app/screens/Dashboard/interfaces/requests";
import { DateRangeKeyType } from "app/utils/dateutils";
import { format } from "date-fns";

export const isInternalUser = () => {
  const urlString = location.href;
  const url = new URL(urlString);
  const searchParams = url.searchParams;
  const internal = searchParams.get("internal");
  return !!internal;
};

const getDimFiltersGAString = (action: string, filters: DimFilter[]) => {
  let filtersString = "";

  filters.forEach((filter, index) => {
    filtersString += `${index ? " -" : ""} ${filter.key}`;
  });

  return `${action} dim filter:${filtersString}`;
};

const getMetricFiltersGAString = (action: string, filters: ValueFilter[]) => {
  let filtersString = "";

  filters.forEach((filter, index) => {
    const isMetricExist = filtersString.includes(filter.metric);
    if (isMetricExist) return;
    filtersString += `${index ? " -" : ""} ${filter.metric}`;
  });

  return `${action} metric filter:${filtersString}`;
};

const getDateRangeString = (action: string, ranges: Record<string, DateRangeKeyType>) => {
  let dateRangeString = "";

  Object.keys(ranges).forEach((key, index) => {
    const range = ranges[key];
    if (!range) return;

    const startDate = format(range.startDate, "MMM/dd/yyyy");
    const endDate = format(range.endDate, "MMM/dd/yyyy");
    dateRangeString += `${index ? "," : ""} ${range.key}:${startDate}-${endDate}`;
  });

  return `${action}:${dateRangeString}`;
};

const getPageLocation = () => {
  let pageLocation = "https://" + location.host + location.pathname;

  if (location.pathname == "oauth") {
    pageLocation += location.hash;
  } else {
    pageLocation += location.hash + location.search;
  }

  return pageLocation;
};

export default {
  getDimFiltersGAString,
  getMetricFiltersGAString,
  getDateRangeString,
  getPageLocation,
};
