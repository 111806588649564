import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import APIService, { APICallState } from "app/services/APIService";
import { RevenueBandType } from "app/services/APIService/organisations";
import { APIServiceFunctionArg } from "common/services/APIService/types";
import CookieService, { CookieNames } from "common/services/CookieService";
import { AsyncThunkReturnType } from "common/slices/types";

export const getOrganisations = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.list>
>("organisations/getOrganisations", async () => await APIService.organisations.list().then((res) => res.json()));

export const getOrganisation = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.get>
>("organisations/getOrganisation", async (data) => await APIService.organisations.get(data).then((res) => res.json()));

export const getAndSetCurrentOrganisation = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.get>
>(
  "organisations/getAndSetCurrentOrganisation",
  async (data) => await APIService.organisations.get(data).then((res) => res.json())
);

export const updateOrganisation = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.update>
>(
  "organisations/updateOrganisation",
  async (data) => await APIService.organisations.update(data).then((res) => res.json())
);

export const createOrganisation = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.create>
>(
  "organisations/createOrganisation",
  async (data) => await APIService.organisations.create(data).then((res) => res.json())
);

export const getOrganisationsAndSetCookies = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.list>
>("organisations/getOrganisationsAndSetCookies", async (_, thunkAPI) => {
  const res = await thunkAPI.dispatch(getOrganisations());
  thunkAPI.dispatch({ type: "organisations/setOrganisationCookies" });
  return res;
});

export const createOrganisationAndSetCookies = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.create>
>("organisations/createOrganisationAndSetCookies", async (data, thunkAPI) => {
  const { payload } = await thunkAPI.dispatch(createOrganisation(data));
  thunkAPI.dispatch({ type: "organisations/setOrganisationCookies" });
  return payload;
});

export const setOrganisationAndSetCookies = createAsyncThunk<AsyncThunkReturnType, OrganisationType>(
  "organisations/setOrganisationAndSetCookies",
  // eslint-disable-next-line require-await
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: "organisations/setOrganisation", payload });
    thunkAPI.dispatch({ type: "organisations/setOrganisationCookies" });
  }
);

export const leaveOrganisation = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.leave>
>(
  "organisations/leaveOrganisation",
  async (data) => await APIService.organisations.leave(data).then((res) => res.json())
);

export const transferOrganisationOwnership = createAsyncThunk<
  AsyncThunkReturnType,
  APIServiceFunctionArg<typeof APIService.organisations.transferOwnership>
>(
  "organisations/transferOrganisationOwnership",
  async (data) => await APIService.organisations.transferOwnership(data).then((res) => res.json())
);

export interface PartnerType {
  id: number;
  name: string;
  domain: string;
  conjura_support: boolean;
  data_provider: string | undefined;
  email_support: boolean;
}

export interface OrganisationConfigType {
  category?: string;
  currency: string;
  revenue_band?: RevenueBandType;
  subcategory?: string;
  timezone: string;
  url?: string;
}

export interface OrganisationType {
  id: number;
  name: string;
  db_name: string;
  public_id: string | undefined;
  organisation_config: OrganisationConfigType | undefined;
  partner: PartnerType | undefined;
}

export interface SubmitStatus {
  status: string | undefined;
  errorMessage: string | undefined;
}

// Define a type for the slice state
export interface OrganisationState {
  organisation: OrganisationType | undefined;
  organisations: OrganisationType[];
  currentOrganisation: OrganisationType | undefined;
  status: string | undefined;
  submitStatus: SubmitStatus | undefined;
  leaveStatus: string | undefined;
  transferStatus: string | undefined;
}

// Define the initial state using that type
export const initialState: OrganisationState = {
  organisation: undefined,
  organisations: [],
  status: undefined,
  submitStatus: undefined,
  currentOrganisation: undefined,
  leaveStatus: undefined,
  transferStatus: undefined,
};

export const organisationsSlice = createSlice({
  name: "organisations",
  initialState: initialState,
  reducers: {
    setOrganisationCookies(state) {
      if (state.currentOrganisation) {
        CookieService.remove(CookieNames.ORGANISATION_ID);
        CookieService.set(CookieNames.ORGANISATION_ID, `${state.currentOrganisation.id}`);
        const { partner: { domain = "app" } = {} } = state.currentOrganisation;
        CookieService.remove(CookieNames.DOMAIN);
        CookieService.set(CookieNames.DOMAIN, domain);
      }
    },
    setOrganisation(state, action: PayloadAction<OrganisationType>) {
      state.currentOrganisation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.pending, (state) => {
      state.status = APICallState.LOADING;
    });

    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      state.status = APICallState.SUCCESS;
      const organisations = action.payload.data?.organisations;
      if (organisations) {
        state.organisations = action.payload.data?.organisations;
        if (!state.currentOrganisation && !!state.organisations.length) {
          const cookieOrganisationId = CookieService.get(CookieNames.ORGANISATION_ID);
          // Set current organisation from cookies.
          const currentOrganisationFromCookie = state.organisations.find(
            (organisation) => organisation.id.toString() === cookieOrganisationId
          );
          const companyId = new URLSearchParams(window.location.search).get("company_id");
          const currentOrganisationFromCompanyId =
            !!companyId && state.organisations.find((organisation) => organisation.public_id === companyId);
          state.currentOrganisation =
            currentOrganisationFromCompanyId || currentOrganisationFromCookie || state.organisations[0];
        }
      }
    });

    builder.addCase(getOrganisations.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(getOrganisation.pending, (state) => {
      state.status = APICallState.LOADING;
      state.organisation = undefined;
    });

    builder.addCase(getOrganisation.fulfilled, (state, action) => {
      if (action.payload.data) {
        const { organisation } = action.payload.data;
        state.status = APICallState.SUCCESS;
        state.organisation = organisation;
      } else {
        state.status = APICallState.FAILED;
      }
    });

    builder.addCase(getOrganisation.rejected, (state) => {
      state.status = APICallState.FAILED;
    });

    builder.addCase(getAndSetCurrentOrganisation.pending, (state) => {
      state.status = APICallState.LOADING;
      state.organisation = undefined;
    });
    builder.addCase(getAndSetCurrentOrganisation.fulfilled, (state, action) => {
      if (action.payload.data) {
        const { organisation } = action.payload.data;
        state.status = APICallState.SUCCESS;
        state.organisation = organisation;
        state.currentOrganisation = organisation;
      } else {
        state.status = APICallState.FAILED;
      }
    });
    builder.addCase(getAndSetCurrentOrganisation.rejected, (state) => {
      state.status = APICallState.FAILED;
    });
    builder.addCase(updateOrganisation.pending, (state) => {
      state.status = APICallState.LOADING;
      state.organisation = undefined;
    });
    builder.addCase(updateOrganisation.fulfilled, (state, action) => {
      if (action.payload.data) {
        const { organisation } = action.payload.data;
        state.status = APICallState.SUCCESS;
        state.organisation = organisation;
        state.currentOrganisation = organisation;
      } else {
        state.status = APICallState.FAILED;
      }
    });
    builder.addCase(updateOrganisation.rejected, (state) => {
      state.status = APICallState.FAILED;
    });
    builder.addCase(createOrganisation.pending, (state) => {
      state.submitStatus = { status: APICallState.LOADING, errorMessage: undefined };
    });
    builder.addCase(createOrganisation.fulfilled, (state, action) => {
      if (action.payload.status_code == 200) {
        state.submitStatus = { status: APICallState.SUCCESS, errorMessage: undefined };
        const organisation = action.payload.data.organisation;
        state.organisations = [...state.organisations, organisation];
        if (!state.currentOrganisation && !!state.organisations.length) {
          state.currentOrganisation = state.organisations[0];
        }
      } else {
        state.submitStatus = { status: APICallState.FAILED, errorMessage: action.payload.message };
      }
    });
    builder.addCase(createOrganisation.rejected, (state) => {
      state.submitStatus = { status: APICallState.FAILED, errorMessage: undefined };
    });
    builder.addCase(leaveOrganisation.pending, (state) => {
      state.leaveStatus = APICallState.LOADING;
    });
    builder.addCase(leaveOrganisation.fulfilled, (state, action) => {
      const leftOrganisation = action.meta.arg.id;

      if (action.payload.status_code == 200) {
        state.organisations = state.organisations.filter(
          (organisations: OrganisationType) => organisations.id !== leftOrganisation
        );
        state.leaveStatus = APICallState.SUCCESS;
      } else {
        state.leaveStatus = APICallState.FAILED;
      }
    });
    builder.addCase(leaveOrganisation.rejected, (state) => {
      state.leaveStatus = APICallState.FAILED;
    });
    builder.addCase(transferOrganisationOwnership.pending, (state) => {
      state.transferStatus = APICallState.LOADING;
    });
    builder.addCase(transferOrganisationOwnership.fulfilled, (state, action) => {
      if (action.payload.status_code == 200) {
        state.transferStatus = APICallState.SUCCESS;
      } else {
        state.transferStatus = APICallState.FAILED;
      }
    });
    builder.addCase(transferOrganisationOwnership.rejected, (state) => {
      state.transferStatus = APICallState.FAILED;
    });
  },
});
export const { setOrganisation, setOrganisationCookies } = organisationsSlice.actions;

export default organisationsSlice.reducer;
