import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";
import queryString from "query-string";

export type SlackIntegrationStatus = "authenticated" | "authentication_required";

export interface SlackIntegration {
  id: number;
  workspace_id: string;
  workspace_name: string;
  organisation_id: number;
  created_at: string;
  updated_at: string | null;
  created_by: string;
  status: SlackIntegrationStatus;
}

export type SlackIntegrationsResponse = { slack_integrations: SlackIntegration[] };
const fetchSlackIntegrations = async (organisationId: number): Promise<Response<SlackIntegrationsResponse>> => {
  return await makeQueryRequest(`${HOST}/v1/slack-integrations?organisation_id=${organisationId}`);
};

const queryKeys = {
  useSlackIntegrations: (organisationId: number | undefined) => ["slackIntegrations", organisationId],
};

const selectSlackIntegrations: (response: {
  data: { slack_integrations: SlackIntegration[] };
}) => SlackIntegration[] = (response) => response.data?.slack_integrations;

const slack = {
  queryKeys,
  getOAuthStartUrl: ({ organisation_id }: { organisation_id: number }) =>
    makeRequest(
      `${HOST}/v1/slack/oauth-start?${queryString.stringify({
        organisation_id,
      })}`
    ),
  oauthCallback: (queryString: string) => makeRequest(`${HOST}/v1/slack/oauth-callback${queryString}`),
  useSlackIntegrations: (organisationId: number | undefined) =>
    useQuery({
      queryKey: queryKeys.useSlackIntegrations(organisationId),
      queryFn: () => fetchSlackIntegrations(organisationId!),
      select: selectSlackIntegrations,
      enabled: !!organisationId,
    }),
};

export default slack;
