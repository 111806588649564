import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

// based on "customerweb-api"
enum WorkflowStateEnum {
  INGESTION_RUN = "ingestion_run",
  TRANSFORMATION_RUN = "transformation_run",
}

// based on "customerweb-api"
export enum WorkflowStatusEnum {
  IN_PROGRESS = "in_progress",
  SUCCESSFUL = "successful",
  FAILED = "failed",
}

export type WorkflowStatusResponse = {
  state: WorkflowStateEnum;
  status: WorkflowStatusEnum;
  started_at: string;
  finished_at: string | undefined;
  last_successful_at: string | undefined;
};

export const fetchWorkflowStatus = async (
  organisationId: number | undefined
): Promise<Response<WorkflowStatusResponse>> => {
  return await makeQueryRequest(`${HOST}/v1/workflows/status?organisation_id=${organisationId}`);
};

const workflows = {
  launch: (organisation_id: number) =>
    makeRequest(`${HOST}/v1/workflows/launch?organisation_id=${organisation_id}`, { method: "POST" }), // TODO: Update with react-query
  useWorkflowStatus: (organisationId: number | undefined) =>
    useQuery({
      queryKey: ["workFlowStatus", organisationId],
      queryFn: () => fetchWorkflowStatus(organisationId),
      enabled: !!organisationId,
    }),
};

export default workflows;
