import APIService from "app/services/APIService";
import { Connector } from "app/services/APIService/connectorsV2";
import { useCurrentOrganisation } from "app/services/APIService/organisations/useCurrentOrganization";
import { ConnectorType } from "common/interfaces/connectors/connectorType";
import { useFlags } from "launchdarkly-react-client-sdk";

import { ConnectorCategoryGroup, CategoryGroup } from "./types";
import { HISTORICAL_CONNECTOR_TYPES, connectorCategoryLabels } from "./utils";

const useConnectorsCategoryGroup = () => {
  const { onboardingEnforceEcommerceSetup, onboardingEnforceAnalyticsSetup, onboardingEnforceMarketingSetup } =
    useFlags();

  const currentOrganisation = useCurrentOrganisation();

  const { data: connectors = [], isLoading: isLoadingConnectors } = APIService.connectorsV2.useConnectors(
    currentOrganisation?.id
  );
  const { data: connectorAuths = [], isLoading: isLoadingConnectorAuths } =
    APIService.connectorAuthorisationsV2.useConnectorAuths(currentOrganisation?.id);

  const isLoading = isLoadingConnectors || isLoadingConnectorAuths;

  const getConnectorsOfCategoryGroup = (connectorCategoryGroup: ConnectorCategoryGroup) => {
    const connectorsOfCategoryGroup: Connector[] = [];
    const historicalConnectors: Connector[] = [];

    connectors.forEach((connector) => {
      const isValidCategory = connectorCategoryGroup.isValidCategory(connector.connector_category);

      if (!isValidCategory) return;

      const isHistoricalConnector = HISTORICAL_CONNECTOR_TYPES.includes(connector.connector_type as ConnectorType);

      if (isHistoricalConnector) {
        historicalConnectors.push(connector);
      } else {
        connectorsOfCategoryGroup.push(connector);
      }
    });

    return [...connectorsOfCategoryGroup, ...historicalConnectors];
  };

  const isCategoryGroupRequired = (connectorCategoryGroup: ConnectorCategoryGroup) => {
    switch (connectorCategoryGroup) {
      case ConnectorCategoryGroup.ECOMMERCE_PLATFORM:
        return !!onboardingEnforceEcommerceSetup;
      case ConnectorCategoryGroup.ANALYTICS_PLATFORM:
        return !!onboardingEnforceAnalyticsSetup;
      case ConnectorCategoryGroup.MARKETING_PLATFORM:
        return !!onboardingEnforceMarketingSetup;
      case ConnectorCategoryGroup.ERP_AND_MARKETPLACE_PLATFORM:
        return false;
      default:
        return false;
    }
  };

  const connectorCategoryGroups: CategoryGroup[] = ConnectorCategoryGroup.values().map((connectorsCategoryGroup) => ({
    ...connectorsCategoryGroup,
    isRequired: isCategoryGroupRequired(connectorsCategoryGroup),
    connectors: getConnectorsOfCategoryGroup(connectorsCategoryGroup),
  }));

  const hasConnectedRequiredCategoryGroups = !connectorCategoryGroups.some((categoryGroup) => {
    const isRequiredCategoryGroup = categoryGroup.isRequired;

    if (isRequiredCategoryGroup) {
      const requiredConnectorTypes = categoryGroup.connectors.map((connector) => connector.connector_type);
      const hasConnectedRequiredConnector = !!connectorAuths.find((connectorAuth) =>
        requiredConnectorTypes.includes(connectorAuth.connector_type_name)
      );

      return !hasConnectedRequiredConnector;
    }
  });

  return {
    categoryGroupsMetadata: connectorCategoryGroups,
    hasConnectedRequiredCategoryGroups,
    connectorsAccounts: connectorAuths,
    isLoading,
  };
};

export default useConnectorsCategoryGroup;
export { HISTORICAL_CONNECTOR_TYPES, connectorCategoryLabels };
