export enum FilterTypeName {
  DIMENSION = "dimension",
  DIMENSION_SET = "dimension_set",
  METRIC = "metric",
  METRIC_ALIAS = "metric_alias",
  METRIC_CONFIGURATION = "metric_configuration",
  GRAPH_TYPE = "graph_type",
  ORDER_BY = "order_by",
  GRANULARITY = "granularity",
  CONFIGURE = "configure",
}

export enum AnalyticsGraphType {
  LINE = "line",
  HORIZONTAL_BAR = "horizontal_bar",
  VERTICAL_BAR = "vertical_bar",
  STACKED_BAR = "stacked_bar",
  DEFAULT = "default",
}

export enum GranularityType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  AUTO = "auto",
}

export enum OrderType {
  TOP = "top",
  BOTTOM = "bottom",
}

interface FilterInterface {
  type: string;
  label: string;
  values: string[];
  default_value: string;
  display: boolean;
  extras?: Record<string, any>;
}

export interface DimensionFilterInterface extends FilterInterface {
  type: "dimension";
}

export interface DimensionSetFilterInterface extends FilterInterface {
  type: "dimension_set";
  extras: {
    dimension_set: Record<string, string[]>; // This is a record which will have the dimension_set value as key and the dimension ids as values
  };
}

export interface MetricFilterInterface extends FilterInterface {
  type: "metric";
}

export interface MetricAliasFilterInterface extends FilterInterface {
  type: "metric_alias";
  extras: {
    metric_alias: Record<string, string[]>;
  };
}

export interface MetricConfigurationFilterInterface extends Omit<FilterInterface, "default_value"> {
  type: "metric_configuration";
  default_value: string[];
  extras?: {
    metric_set: Record<string, string[]>; // This is a record which will have the metric_set value as key and the metric ids as values
  };
}

export interface GraphTypeFilterInterface extends FilterInterface {
  type: "graph_type";
  values: AnalyticsGraphType[];
  default_value: AnalyticsGraphType;
}

export interface GranularityFilterInterface extends FilterInterface {
  type: "granularity";
  values: GranularityType[];
  default_value: GranularityType;
}

export interface OrderByFilterInterface extends Omit<FilterInterface, "default_value"> {
  type: "order_by";
  values: OrderType[];
  default_value: {
    order: OrderType;
    limit: Partial<Record<AnalyticsGraphType, number>>;
  };
}

export type ConfigureFilterValueType = {
  type: "dimension" | "metric";
  value: string;
};
export interface ConfigureFilterInterface extends Omit<FilterInterface, "values" | "default_value"> {
  type: "configure";
  values: ConfigureFilterValueType[];
  default_value: ConfigureFilterValueType[];
  extras?: {
    suggestion: Record<string, ConfigureFilterValueType[]>; // This is a record which will have the suggestion value as key and the ConfigureFilterValueType as values
  };
}

export type FilterType =
  | DimensionFilterInterface
  | DimensionSetFilterInterface
  | MetricFilterInterface
  | MetricConfigurationFilterInterface
  | MetricAliasFilterInterface
  | GraphTypeFilterInterface
  | OrderByFilterInterface
  | GranularityFilterInterface
  | ConfigureFilterInterface;

export type FilterTypeMap = {
  [filterType in FilterType["type"]]: Extract<FilterType, { type: filterType }>;
};

export type AnalyticsComponentFilterValueMap = {
  [filterType in FilterType["type"]]: Extract<FilterType, { type: filterType }>["default_value"];
};
