import makeNoAuthRequest from "common/services/APIService/makeNoAuthRequest";
import makeRequest from "common/services/APIService/makeRequest";
import { HOST } from "common/utils/constants";

const connectors = {
  oauthCallback: (queryString: string) => makeRequest(`${HOST}/v1/oauth-callback${queryString}`),
  shopifyInstall: (queryString: string) => makeNoAuthRequest(`${HOST}/v1/install/Shopify${queryString}`),
  getConfigSchema: ({ connector_type, organisation_id }: { connector_type: string; organisation_id: number }) =>
    makeRequest(`${HOST}/v1/connectors/${connector_type}/config_schema?organisation_id=${organisation_id}`),
  submitConfig: ({
    connector_type,
    organisation_id,
    form_submission,
  }: {
    connector_type: string;
    organisation_id: number;
    form_submission: { data: Record<string, any> };
  }) =>
    makeRequest(`${HOST}/v1/connectors/${connector_type}/config_submit?organisation_id=${organisation_id}`, {
      method: "POST",
      body: form_submission,
    }),
  reauthenticateConnector: ({
    connector_type,
    connector_auth_to_reauthenticate,
    organisation_id,
  }: {
    connector_type: string;
    connector_auth_to_reauthenticate: { connector_auth_id: string };
    organisation_id: number;
  }) =>
    makeRequest(`${HOST}/v1/connectors/${connector_type}/reauthenticate_connector?organisation_id=${organisation_id}`, {
      method: "POST",
      body: connector_auth_to_reauthenticate,
    }),
  getAuthSchema: ({ connector_type, organisation_id }: { connector_type: string; organisation_id: number }) =>
    makeRequest(`${HOST}/v1/connectors/${connector_type}/auth_schema?organisation_id=${organisation_id}`),
  submitAuth: ({
    connector_type,
    organisation_id,
    form_submission,
  }: {
    connector_type: string;
    organisation_id: number;
    form_submission: { data: Record<string, any> };
  }) =>
    makeRequest(`${HOST}/v1/connectors/${connector_type}/auth_submit?organisation_id=${organisation_id}`, {
      method: "POST",
      body: form_submission,
    }),
};

export default connectors;
