import actions from "./actions";
import authentication from "./authentication";
import connector_authorisations from "./connector_authorisations";
import connectorAuthorisationsV2 from "./connectorAuthorisationsV2";
import connectors from "./connectors";
import connectorsV2 from "./connectorsV2";
import dashboards from "./dashboards";
import dashboardViews from "./dashboardViews";
import dataImport from "./dataImport";
import filters from "./filters";
import invites from "./invites";
import markdown from "./markdown";
import members from "./members";
import membersV2 from "./membersV2";
import metrics from "./metrics";
import oidc from "./oidc";
import organisations from "./organisations";
import productPermissions from "./productPermissions";
import slack from "./slack";
import stripe from "./stripe";
import subscriptions from "./subscriptions";
import transformations from "./transformations";
import users from "./users";
import usersV2 from "./usersV2";
import workflows from "./workflows";

export type APICallStateType = "loading" | "success" | "failed" | undefined;
export const APICallState: Record<string, APICallStateType> = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILED: "failed",
};

export default {
  authentication,
  users,
  usersV2,
  connectors,
  connectorsV2,
  connector_authorisations,
  connectorAuthorisationsV2,
  organisations,
  members,
  membersV2,
  invites,
  workflows,
  dashboards,
  stripe,
  subscriptions,
  actions,
  markdown,
  dataImport,
  productPermissions,
  oidc,
  transformations,
  dashboardViews,
  slack,
  metrics,
  filters,
};
