// Types based upon customerweb-api/chalicelib/models/connectors/types.py
export enum ConnectorType {
  BIGCOMMERCE = "BigCommerce",
  FACEBOOK = "Facebook",
  SHOPIFY = "Shopify",
  GOOGLE_ANALYTICS = "Google Analytics",
  GOOGLE_ADS = "Google Ads",
  BRIGHTPEARL = "Brightpearl",
  KLAVIYO = "Klaviyo",
  RECHARGE = "Recharge",
  WOOCOMMERCE = "Woocommerce",
  UNDEFINED = "Undefined",
  TIKTOK_MARKETING = "TikTok Marketing",
  GOOGLE_ANALYTICS_4 = "Google Analytics 4",
  AMAZON_SELLER_PARTNER = "Amazon Seller Partner",
  AMAZON_ADS = "Amazon Ads",
  BING_ADS = "Bing Ads",
  LINNWORKS = "Linnworks",
}
