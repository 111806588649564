import { CohortPrimaryPresetRangeLabel } from "app/screens/Dashboard/interfaces";
import { createStaticRanges } from "app/screens/Dashboard/utils";
import { dateRangePresets } from "app/utils/dateutils";

export const primaryPresetRanges = () => {
  return createStaticRanges([
    {
      label: CohortPrimaryPresetRangeLabel.THIS_MONTH,
      range: () => dateRangePresets.monthToDate(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_MONTH,
      range: () => dateRangePresets.lastMonth(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.THIS_QUARTER,
      range: () => dateRangePresets.quarterToDate(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_QUARTER,
      range: () => dateRangePresets.lastQuarter(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.THIS_YEAR,
      range: () => dateRangePresets.yearToDate(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_YEAR,
      range: () => dateRangePresets.lastYear(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_6_MONTHS,
      range: () => dateRangePresets.last6Months(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_12_MONTHS,
      range: () => dateRangePresets.last12Months(),
    },
    {
      label: CohortPrimaryPresetRangeLabel.LAST_24_MONTHS,
      range: () => dateRangePresets.last24Months(),
    },
  ]);
};
