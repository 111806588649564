import {
  startOfDay as _startOfDay,
  startOfWeek as _startOfWeek,
  startOfMonth as _startOfMonth,
  startOfQuarter as _startOfQuarter,
  startOfYear as _startOfYear,
  endOfDay as _endOfDay,
  endOfWeek as _endOfWeek,
  endOfMonth as _endOfMonth,
  endOfQuarter as _endOfQuarter,
  endOfYear as _endOfYear,
  subDays as _subDays,
  subWeeks as _subWeeks,
  subMonths as _subMonths,
  subQuarters as _subQuarters,
  subYears as _subYears,
  isSameDay as _isSameDay,
  addDays as _addDays,
  addMonths as _addMonths,
  addYears as _addYears,
} from "date-fns";
import moment from "moment";

export type DateRangeType = {
  startDate: Date;
  endDate: Date;
  key?: string;
  label?: string;
};

export type DateRangeKeyType = {
  startDate: Date;
  endDate: Date;
  key: string;
  label: string;
};

/* by convention, our start of the week is Monday.
 * But startOfWeekDateFns considers Sunday as the first day.
 * Hence, we changed the weekStartsOn from the default value 0 to 1.
 * weekStartsOn possible value are 0-6 for sunday-saturday
 */
export const startOfWeek = (date: Date): Date => _startOfWeek(date, { weekStartsOn: 1 });
export const endOfWeek = (date: Date): Date => _endOfWeek(date, { weekStartsOn: 1 });

export const today = (): Date => _startOfDay(new Date());
export const yesterday = (): Date => _startOfDay(_subDays(new Date(), 1));
export const endOfYesterday = (): Date => _endOfDay(_subDays(new Date(), 1));

export const lastDay = (): DateRangeType => ({
  startDate: yesterday(),
  endDate: endOfYesterday(),
});

export const lastWeek = (): DateRangeType => ({
  startDate: startOfWeek(_subDays(yesterday(), 7)),
  endDate: endOfWeek(_subDays(yesterday(), 7)),
});

export const lastMonth = (): DateRangeType => ({
  startDate: _startOfMonth(_subMonths(yesterday(), 1)),
  endDate: _endOfMonth(_subMonths(yesterday(), 1)),
});

export const lastQuarter = (): DateRangeType => ({
  startDate: _startOfQuarter(_subQuarters(yesterday(), 1)),
  endDate: _endOfQuarter(_subQuarters(yesterday(), 1)),
});

export const lastYear = (): DateRangeType => ({
  startDate: _startOfYear(_subYears(yesterday(), 1)),
  endDate: _endOfYear(_subYears(yesterday(), 1)),
});

export const last7Days = (): DateRangeType => ({
  startDate: _startOfDay(_subDays(yesterday(), 6)),
  endDate: endOfYesterday(),
});

export const last30Days = (): DateRangeType => ({
  startDate: _startOfDay(_subDays(yesterday(), 29)),
  endDate: endOfYesterday(),
});

export const last60Days = (): DateRangeType => ({
  startDate: _startOfDay(_subDays(yesterday(), 59)),
  endDate: endOfYesterday(),
});

export const last90Days = (): DateRangeType => ({
  startDate: _startOfDay(_subDays(yesterday(), 89)),
  endDate: endOfYesterday(),
});

export const last6Months = (): DateRangeType => ({
  startDate: _startOfMonth(_subMonths(yesterday(), 5)),
  endDate: endOfYesterday(),
});

export const last12Months = (): DateRangeType => ({
  startDate: _startOfMonth(_subMonths(yesterday(), 11)),
  endDate: endOfYesterday(),
});

export const last24Months = (): DateRangeType => ({
  startDate: _startOfMonth(_subMonths(yesterday(), 23)),
  endDate: endOfYesterday(),
});

export const weekToDate = (): DateRangeType => ({
  startDate: startOfWeek(yesterday()),
  endDate: endOfYesterday(),
});

export const monthToDate = (): DateRangeType => ({
  startDate: _startOfMonth(yesterday()),
  endDate: endOfYesterday(),
});

export const quarterToDate = (): DateRangeType => ({
  startDate: _startOfQuarter(yesterday()),
  endDate: endOfYesterday(),
});

export const yearToDate = (): DateRangeType => ({
  startDate: _startOfYear(yesterday()),
  endDate: endOfYesterday(),
});

// all previous functions are relative datetime range values to the input range.
export const previousDay = (dateRange: DateRangeType): DateRangeType => ({
  startDate: _subDays(dateRange.startDate, 1),
  endDate: _subDays(dateRange.endDate, 1),
});

export const previousWeek = (dateRange: DateRangeType): DateRangeType => ({
  startDate: _subWeeks(dateRange.startDate, 1),
  endDate: _subWeeks(dateRange.endDate, 1),
});

export const previousMonth = (dateRange: DateRangeType): DateRangeType => {
  // we are not strongly opinionated on situations such as march 30th - 1 month
  // so we leave it to datetime to deal with it
  return {
    startDate: _subMonths(dateRange.startDate, 1),
    endDate: _subMonths(dateRange.endDate, 1),
  };
};

export const previous30Days = (dateRange: DateRangeType): DateRangeType => {
  return {
    startDate: _subDays(dateRange.startDate, 30),
    endDate: _subDays(dateRange.endDate, 30),
  };
};

export const previous60Days = (dateRange: DateRangeType): DateRangeType => {
  return {
    startDate: _subDays(dateRange.startDate, 60),
    endDate: _subDays(dateRange.endDate, 60),
  };
};

export const previous90Days = (dateRange: DateRangeType): DateRangeType => {
  return {
    startDate: _subDays(dateRange.startDate, 90),
    endDate: _subDays(dateRange.endDate, 90),
  };
};

export const previousYear = (dateRange: DateRangeType): DateRangeType => ({
  startDate: _subYears(dateRange.startDate, 1),
  endDate: _subYears(dateRange.endDate, 1),
});

export const dayAlignedPreviousMonth = (dateRange: DateRangeType): DateRangeType => {
  let startDate = _subWeeks(dateRange.startDate, 4);
  let endDate = _subWeeks(dateRange.endDate, 4);

  // if the month after subtracting 28 (for the start) is the same, we subtract another week
  if (startDate.getMonth() === dateRange.startDate.getMonth()) {
    startDate = _subWeeks(startDate, 1);
    endDate = _subWeeks(endDate, 1);
  }
  return { startDate, endDate };
};

export const dayAlignedPreviousYear = (dateRange: DateRangeType): DateRangeType => ({
  startDate: _subWeeks(dateRange.startDate, 52),
  endDate: _subWeeks(dateRange.endDate, 52),
});

export const thisBlackFriday = (): DateRangeType => ({
  startDate: _startOfDay(new Date(2024, 10, 23)),
  endDate: _endOfDay(new Date(2024, 11, 5)),
});

export const lastBlackFriday = (): DateRangeType => ({
  startDate: _startOfDay(new Date(2023, 10, 18)),
  endDate: _endOfDay(new Date(2023, 10, 30)),
});

export const dateRangePresets = {
  startOfWeek,
  endOfWeek,
  today,
  yesterday,
  lastDay,
  lastWeek,
  lastMonth,
  lastQuarter,
  lastYear,
  last7Days,
  last30Days,
  last60Days,
  last90Days,
  last6Months,
  last12Months,
  last24Months,
  weekToDate,
  monthToDate,
  quarterToDate,
  yearToDate,
  previousDay,
  previousWeek,
  previousMonth,
  previousYear,
  previous30Days,
  previous60Days,
  previous90Days,
  dayAlignedPreviousMonth,
  dayAlignedPreviousYear,
  thisBlackFriday,
  lastBlackFriday,
};

/**
 * Function to get the next date.
 * @param date - The date to which the next date is calculated.
 * @returns - The next date.
 */
export const nextDate = (date: Date) => _addDays(date, 1);

/**
 * Function to get the 1st date of the next month.
 * @param date - The date from which the next month's first date is calculated.
 * @returns - The 1st date of the next month.
 */
export const firstDateOfNextMonth = (date: Date) => {
  const nextMonth = _addMonths(date, 1);
  return _startOfMonth(nextMonth);
};

/**
 * Function to get the 1st date and 1st month of the next year.
 * @param date - The date from which the next year's first date is calculated.
 * @returns - The 1st date of the next year.
 */
export const firstDateOfNextYear = (date: Date) => {
  const nextYear = _addYears(date, 1);
  return _startOfYear(nextYear);
};

export const areRangeEqual = (range1: DateRangeType, range2: DateRangeType) => {
  return _isSameDay(range2.startDate, range1.startDate) && _isSameDay(range2.endDate, range1.endDate);
};

export const formatDateRange = (range: DateRangeType) => {
  if (range.startDate && range.endDate) {
    if (range.startDate.getFullYear() === range.endDate.getFullYear()) {
      return `${moment(range.startDate).format("MMM DD")} - ${moment(range.endDate).format("MMM DD, YYYY")}`;
    }
    return `${moment(range.startDate).format("MMM DD, YYYY")} - ${moment(range.endDate).format("MMM DD, YYYY")}`;
  }
  return "-";
};

export const formatMonthRange = (range: DateRangeType) => {
  if (range.startDate && range.endDate) {
    if (range.startDate.getFullYear() === range.endDate.getFullYear()) {
      return `${moment(range.startDate).format("MMM")} - ${moment(range.endDate).format("MMM, YYYY")}`;
    }
    return `${moment(range.startDate).format("MMM, YYYY")} - ${moment(range.endDate).format("MMM, YYYY")}`;
  }
  return "-";
};

export const getTimezoneOffsetInMs = (date: Date) => {
  const MS_PER_MINUTE = 60000;
  return date.getTimezoneOffset() * MS_PER_MINUTE;
};

export const getRangeWithEndOfDayEndDate = (range: DateRangeKeyType) => {
  /*
  Takes a range and returns a new range with the endDate as the end of day
  Example:
  Input range: {startDate: "2020-10-10T00:00:00", endDate: "2020-10-11T00:00:00"}
  Output range: {startDate: "2020-10-10T00:00:00", endDate: "2020-10-11T23:59:59"}

  This function doesn't mutate the input object.
  */
  return {
    ...range,
    endDate: _endOfDay(range.endDate),
  };
};

export const getRangesWithEndOfDayEndDate = (ranges: Record<string, DateRangeKeyType>) => {
  /*
  Takes ranges and returns a new ranges object with the endDate as the end of day
  Example:
  Input range: {"key1": {startDate: "2020-10-10T00:00:00", endDate: "2020-10-11T00:00:00"}}
  Output range: {"key1": {startDate: "2020-10-10T00:00:00", endDate: "2020-10-11T23:59:59"}}

  This function doesn't mutate the input object.
  */
  const updatedRanges: Record<string, DateRangeKeyType> = {};
  Object.keys(ranges).forEach((key) => {
    updatedRanges[key] = getRangeWithEndOfDayEndDate(ranges[key]);
  });

  return updatedRanges;
};
