import { useQuery } from "@tanstack/react-query";
import {
  DashboardMetadataRequest,
  AvailabilityRequest,
  DashboardListRequest,
  DashboardAvailability,
  DashboardMetadataInterface,
} from "app/screens/Dashboard/interfaces";
import { makeQueryRequest, HttpMethod } from "common/services/APIService";
import { REACT_APP_OCARINA_HOST } from "common/utils/constants";

export interface DashboardListItem {
  id: string;
  label: string;
  tags: string[];
}

const fetchDashboardsList = async (
  dashboardRequest: DashboardListRequest,
  token: string
): Promise<DashboardListItem[]> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/dashboards/list/`,
    {
      method: HttpMethod.POST,
      body: dashboardRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const fetchDashboardMetaData = async (
  dashboardRequest: DashboardMetadataRequest,
  token: string
): Promise<DashboardMetadataInterface> => {
  return await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/dashboards/`,
    {
      method: HttpMethod.POST,
      body: dashboardRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );
};

const fetchDashboardAvailability = async (
  availabilityRequest: AvailabilityRequest,
  token: string
): Promise<DashboardAvailability | undefined> => {
  const response = await makeQueryRequest(
    `${REACT_APP_OCARINA_HOST}/v1/dashboards/availability/`,
    {
      method: HttpMethod.POST,
      body: availabilityRequest,
    },
    { auth: false },
    { Authorization: token, "Content-Type": "application/json" }
  );

  if (!!response?.data && !!response?.connected_sources) return response;
  return undefined;
};

const dashboards = {
  useDashboardsList: (dashboardListRequest: DashboardListRequest, token?: string, enabled = true) =>
    useQuery({
      queryKey: ["dashboardsList", dashboardListRequest.organisation_id, token],
      queryFn: () => fetchDashboardsList(dashboardListRequest, token!),
      enabled: enabled && Boolean(token),
    }),
  useDashboardMetaData: (dashboardMetadataRequest: DashboardMetadataRequest, token?: string, enabled = true) =>
    useQuery({
      queryKey: [
        "dashboardMetaData",
        dashboardMetadataRequest.dashboard_id,
        dashboardMetadataRequest.organisation_id,
        token,
      ],
      queryFn: () => fetchDashboardMetaData(dashboardMetadataRequest, token!),
      enabled: enabled && Boolean(token),
    }),
  useDashboardAvailability: (availabilityRequest: AvailabilityRequest, token?: string, enabled = true) =>
    useQuery({
      queryKey: ["dashboardAvailability", availabilityRequest.organisation_id, token],
      queryFn: () => fetchDashboardAvailability(availabilityRequest, token!),
      enabled: enabled && Boolean(token),
    }),
};

export default dashboards;
