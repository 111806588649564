import { useQuery } from "@tanstack/react-query";
import { makeQueryRequest, Response } from "common/services/APIService";
import { HOST } from "common/utils/constants";

// Types based upon conjura-transform/app/var_set_bases/var_set_bases.yml
export enum ConnectorCategory {
  ERP = "erp",
  ECOMMERCE = "ecommerce",
  MARKETING = "marketing",
  WEB_ANALYTICS = "web_analytics",
  EMAIL = "email",
  ECOMMERCE_SUBSCRIPTION = "ecommerce_subscription",
  MARKETPLACE = "marketplace",
}

export interface Connector {
  label: string;
  connector_type: string;
  connector_category: ConnectorCategory;
  airbyte_name: string;
  oauth_flow: boolean;
}

const fetchConnectors = async (organisationId: number | undefined): Promise<Response<any>> => {
  return await makeQueryRequest(`${HOST}/v1/connectors?organisation_id=${organisationId}`);
};

const selectConnectors: (response: { data: { connectors: Connector[] } }) => Connector[] = (response) =>
  response.data?.connectors;

const connectorsV2 = {
  useConnectors: (organisationId: number | undefined) =>
    useQuery({
      queryKey: ["connectors", organisationId],
      queryFn: () => fetchConnectors(organisationId),
      select: selectConnectors,
      enabled: typeof organisationId === "number",
    }),
};

export default connectorsV2;
